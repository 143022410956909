export default {

  name: 'Cancellation',

  cancellation: {
    firstname: '',
    lastname: '',
    customerEmail: '',
    customerEmailConfirm: '',
    customerNumber: '',
    contractNumber: '',
    onTime: 'true',
    cancellationReason: '',
    nextTimeCancellation: 'true',
    requestedCancellationDate: null,
  }
}