<template>
  <div v-if="cancellation">
    <form novalidate @submit.prevent="validateCancellation">
      <div class="grid-x grid-padding-x">
        <div class="cell large-6 medium-6 small-12">
          <md-field :class="getValidationClass('firstname')">
            <label>Vorname</label>
            <md-input name="firstnameId" id="firstnameId" autocomplete="off" v-model="cancellation.firstname"
                      :disabled="sending"/>
            <span class="md-error" v-if="!$v.cancellation.firstname.required">Vorname ist notwendig</span>
          </md-field>
        </div>
        <div class="cell large-6 medium-6 small-12">
          <md-field :class="getValidationClass('lastname')">
            <label>Nachname</label>
            <md-input name="lastnameId" id="lastnameId" autocomplete="off" v-model="cancellation.lastname"
                      :disabled="sending"/>
            <span class="md-error" v-if="!$v.cancellation.lastname.required">Nachname ist notwendig</span>
          </md-field>
        </div>
        <div class="cell large-6 medium-6 small-12">
          <md-field :class="getValidationClass('customerEmail')">
            <label>Bei ai42 hinterlegte E-Mail-Adresse</label>
            <md-input name="customerEmailId" id="customerEmailId" autocomplete="off"
                      v-model="cancellation.customerEmail"
                      :disabled="sending"/>
            <span class="md-error" v-if="!$v.cancellation.customerEmail.required">E-Mail-Adresse ist notwendig</span>
            <span class="md-error" v-if="!$v.cancellation.customerEmail.email">E-Mail-Adresse ist ungültig</span>
          </md-field>
        </div>
        <div class="cell large-6 medium-6 small-12">
          <md-field :class="getValidationClass('customerEmailConfirm')">
            <label>E-Mail-Adresse wiederholen</label>
            <md-input name="customerEmailConfirmId" id="customerEmailConfirmId" autocomplete="off"
                      v-model="cancellation.customerEmailConfirm" :disabled="sending"/>
            <span class="md-error" v-if="!$v.cancellation.customerEmail.required">E-Mail-Adresse ist notwendig</span>
            <span class="md-error"
                  v-else-if="!$v.cancellation.customerEmailConfirm.email">E-Mail-Adresse ist ungültig</span>
            <span class="md-error" v-else-if="!$v.cancellation.customerEmailConfirm.sameAsCustomerEmail">E-Mail-Adresse stimmt nicht überein</span>
          </md-field>
        </div>
        <div class="cell large-6 medium-6 small-12">
          <md-field :class="getValidationClass('customerNumber')">
            <label>Kundennummer</label>
            <md-input name="customerNumberId" id="customerNumberId" autocomplete="off"
                      v-model="cancellation.customerNumber" :disabled="sending"/>
            <span class="md-error" v-if="!$v.cancellation.customerNumber.required">Kundennummer ist notwendig</span>
          </md-field>
        </div>
        <div class="cell large-6 medium-6 small-12">
          <md-field :class="getValidationClass('contractNumber')">
            <label>Vertragsnummer</label>
            <md-input name="contractNumberId" id="contractNumberId" autocomplete="off"
                      v-model="cancellation.contractNumber" :disabled="sending"/>
            <span class="md-error" v-if="!$v.cancellation.contractNumber.required">Vertragsnummer ist notwendig</span>
          </md-field>
        </div>
        <div class="cell">
          <br>
          <label>Art der Kündigung</label>
          <md-radio v-model="cancellation.onTime" value="true" class="md-primary">Fristgerecht</md-radio>
          <md-radio v-model="cancellation.onTime" value="false" class="md-primary">Außerordentlich</md-radio>
        </div>
        <div class="cell">
          <md-field v-if="cancellation.onTime === 'false'" :class="getValidationClass('cancellationReason')">
            <label>Kündigungsgrund</label>
            <md-textarea v-model="cancellation.cancellationReason" maxlength="255"
                         placeholder="Bitte beschreiben Sie kurz Ihren Kündigungsgrund"></md-textarea>
            <span class="md-error" v-if="!$v.cancellation.cancellationReason.required">Kündigungsgrund ist notwendig</span>
          </md-field>
        </div>
        <br>
        <div class="cell">
          <label>Zeitpunkt der Wirksamkeit der Kündigung</label>
          <md-radio v-model="cancellation.nextTimeCancellation" value="true" class="md-primary">Nächstmöglicher
            Zeitpunkt
          </md-radio>
          <md-radio v-model="cancellation.nextTimeCancellation" value="false" class="md-primary">Datum selbst angeben
          </md-radio>
        </div>
        <div class="cell large-6 medium-6 small-12">
          <md-datepicker v-if="cancellation.nextTimeCancellation === 'false'"
                         id="cancellation-date-dp" :md-debounce="10000" :md-open-on-focus="true" md-immediately
                         v-model="requestedCancellationDate" :disabled="sending"
                         @md-closed="writeBackDates" @md-changed="writeBackDates"
                         :md-disabled-dates="disablePreviousDays"
                         :class="getValidationClass('requestedCancellationDate')">
            <label>Kündigungsdatum</label>
            <span class="md-error" v-if="$v.cancellation.requestedCancellationDate.$model === 'Invalid date'">Kündigungsdatum ist notwendig</span>
          </md-datepicker>
        </div>
      </div>
    </form>
  </div>
</template>


<script>
  import jQuery from 'jquery';
  import Vue from 'vue';
  import {validationMixin} from 'vuelidate'
  import {
    required,
    requiredIf,
    sameAs,
    email,
  } from 'vuelidate/lib/validators'

  export default {
    name: 'CancellationForm',
    mixins: [validationMixin],

    props: {
      cancellation: {
        required: true
      },
      sending: {
        required: true
      },
    },

    created() {
      this.initSyncHandler(true);
    },

    beforeDestroy() {
      this.initSyncHandler(false)
    },

    data() {
      return {
        requestedCancellationDate: Vue.moment().add(1, 'days').format('DD.MM.YYYY'),

        disablePreviousDays: date => {
          return Vue.moment().isAfter(date);
        },

        invalid: true,
      }
    },

    validations: {
      cancellation: {
        firstname: {
          required,
        },
        lastname: {
          required,
        },
        customerEmail: {
          required,
          email,
        },
        customerEmailConfirm: {
          required,
          email,
          sameAsCustomerEmail: sameAs('customerEmail')
        },
        customerNumber: {
          required,
        },
        contractNumber: {
          required,
        },
        onTime: {
          required,
        },
        cancellationReason: {
          required: requiredIf(function () {
            return this.cancellation.onTime === 'false'
          })
        },
        requestedCancellationDate: {
          required: requiredIf(function () {
            return this.cancellation.nextTimeCancellation === 'false'
          })
        },
      },
    },

    methods: {

      initSyncHandler(init) {
        if (init) {
          setTimeout(() => {
            jQuery('#cancellation-date-dp').children('input').bind('blur', () => {
              this.requestedCancellationDate = jQuery('#cancellation-date-dp').children('input').val();
              this.writeBackDates();
            });
          }, 500);
        } else {
          jQuery('#cancellation-date-dp').children('input').unbind('blur');
        }
      },

      writeBackDates() {
        this.requestedCancellationDate = jQuery('#cancellation-date-dp').children('input').val();
        if (this.requestedCancellationDate !== '' && this.requestedCancellationDate != null) {
          this.cancellation.requestedCancellationDate = Vue.moment(this.requestedCancellationDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
        } else {
          this.cancellation.requestedCancellationDate = Vue.moment().add(1, 'days').format('DD.MM.YYYY');
        }
      },

      getValidationClass(fieldName) {
        let field = this.$v.cancellation[fieldName];

        if (field) {
          return {
            'md-invalid': field.$invalid && field.$dirty
          }
        }
      },

      validateCancellation() {
        if (this.cancellation.nextTimeCancellation === 'false') {
          this.writeBackDates();
        }

        this.$v.$touch();

        this.invalid = this.$v.$invalid;

        if (this.cancellation.customerEmail !== this.cancellation.customerEmailConfirm) {
          this.invalid = true;
        }

        if (this.cancellation.onTime === 'false' && this.cancellation.cancellationReason === '') {
          this.invalid = true;
        }

        if (this.cancellation.requestedCancellationDate == null && this.cancellation.nextTimeCancellation === 'false') {
          this.invalid = true;
        }
      },

    },

    computed: {},
  };
</script>

<style lang="scss">
</style>