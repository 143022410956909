<template>
  <div>
    <div class="grid-x grid-padding-x">
      <div class="cell callout success">
        <h3>
          <md-icon style="color: black; font-size: 2rem!important;">cancel_presentation</md-icon>&nbsp;&nbsp;&nbsp;Kündigen
        </h3>
      </div>
    </div>

    <div class="grid-x grid-padding-x grid-padding-y">
      <div v-if="webAppConfig.soseContractCancellationDisabled" class="cell" style="padding-right: 35px;">
        <md-card style="width: 100%">
          <md-card-header>
            <div class="md-title">
              <p style="font-weight: 300">
                Lieber SoSE-Nutzer,
                <br><br>
                derzeit steht das System aufgrund von aktuellen <b style="color: seagreen">Wartungsarbeiten</b> nicht
                zur Verfügung.
                <br>
                Sobald diese beendet sind, können Sie hier mit Ihrer Kündigung fortfahren.
                <br><br>
                Vielen Dank für Ihr Verständnis.
                <br>
                ai42 Team
              </p>
              <br>
              <md-progress-bar md-mode="buffer"/>
            </div>
          </md-card-header>
        </md-card>
      </div>
      <div v-else class="cell" style="padding-right: 35px;">
        <md-card v-if="!success">
          <md-card-header>
            <div class="md-title">Schnell und unkompliziert online kündigen</div>
            <div class="md-subhead">Am einfachsten können Sie über unseren Online-Vertragsservice kündigen. Wir zeigen
              Ihnen alle möglichen Optionen und informieren Sie über wichtige Details.
            </div>
            <br>
            <button class="button primary success" @click="onSelectAndCancel">Vertrag wählen und weiter zur Kündigung
            </button>
          </md-card-header>
          <br>
          <md-card-content>
            <div class="md-title-textarea text-justify">Alternativ können Sie unser Kündigungsformular nutzen und hier
              Ihre Angaben zur Kündigung machen.
            </div>
            <br>
            <div class="grid-x">
              <div class="cell large-8">

                <CancellationForm ref="cancellationForm" :cancellation="cancellation"
                                  :sending="sending"></CancellationForm>

                <br>
                <button class="button primary alert" @click="onCancel">Vertrag jetzt kündigen</button>
              </div>
              <div class="cell large-4">
              </div>
            </div>

          </md-card-content>
        </md-card>
        <md-card v-else>
          <md-card-header>
            <div class="md-title">Ihre Kündigung ist bei uns eingetroffen</div>
            <br>
            <div class="md-title" style="color: cornflowerblue;">Sie erhalten in Kürze eine E-Mail von uns</div>
          </md-card-header>
          <md-card-content>
            <div class="md-title-textarea text-justify">
              Es wurde Ihnen eine E-Mail über die Bestätigung Ihrer Kündigung an <b>{{ cancellation.customerEmail }}</b>
              gesendet.
              <span v-if="cancellationResponse.pdfDownloadKey">
              In dieser E-Mail liegt ein PDF-Dokument über Ihre Kündigungszusammenfassung bei.
              Zusätzlich können Sie dieses auch nochmal hier herunterladen.
              </span>
              <span v-else>
                <br>
                Unser Kundenservice wird sich zeitnah mit Ihnen in Verbindung setzen.
              </span>
              <br><br>
              <div v-if="cancellationResponse.pdfDownloadKey" class="md-subhead">
                Am {{ cancellationSubmittedDate | moment("DD.MM.YYYY") }} um
                {{ cancellationSubmittedDate | moment("h:mm") }} haben Sie folgende Daten an uns übermittelt:
              </div>
              <br>
              <div v-if="cancellationResponse.pdfDownloadKey" @click="onDownloadCancellationSummaryPdf"
                   class="button warn" style="margin-right: 1rem">
                <i class="fi-page-multiple"></i>&nbsp;&nbsp;&nbsp;Kündigungszusammenfassung
                <md-tooltip>
                  Kündigungszusammenfassung für Vertragsnummer <b>{{ cancellation.contractNumber }}</b> als PDF
                  herunterladen.
                </md-tooltip>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="confirmCancellationRequestDialog">
      <div class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title>
          <md-icon>priority_high</md-icon>&nbsp;&nbsp;&nbsp;Kündigung einreichen
        </md-dialog-title>
        <div class="cell">
          <md-content>Wollen Sie die Kündigung für Vertragsnummer <span
              class="group-title">{{ cancellation.contractNumber }}</span> wirklich abschicken?
          </md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <md-checkbox v-model="absolutelySure" :disabled="sending">Ich bin mir absolut sicher!</md-checkbox>
        </div>
        <div class="cell text-center">
          <div class="grid-x grid-padding-x grid-padding-y">
            <div class="cell large-3 medium-2 small-2"></div>
            <div class="cell large-6 medium-8 small-8">
              <vue-recaptcha
                  ref="recaptcha"
                  :sitekey="siteKey"
                  @verify="onVerify"
                  @expired="onExpired">
              </vue-recaptcha>
              <br>
            </div>
            <div class="cell large-3 medium-2 small-2"></div>
          </div>
        </div>
        <div class="cell text-center">
          <button @click="onCancelContract" class="button alert"
                  :disabled="sending || !absolutelySure || recaptchaResponse.length === 0">
            Ja, jetzt kündigen
          </button>
          <button class="button success" style="margin-left: 1rem;" @click="confirmCancellationRequestDialog = false"
                  type="button" :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="confirmCancellationRequestDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import Vue from 'vue';
import CancellationForm from '../components/forms/CancellationForm';
import Cancellation from '../entities/Cancellation';
import OrganizationService from '../services/OrganizationService';
import HttpErrorHandler from '../services/HttpErrorHandler';
import BinaryService from '../services/BinaryService';

export default {
  name: "Cancellation",

  components: {
    CancellationForm,
    VueRecaptcha
  },

  created() {
    this.cancellation = JSON.parse(JSON.stringify(Cancellation.cancellation));
  },

  data() {
    return {
      cancellation: Cancellation,
      sending: false,
      success: false,
      confirmCancellationRequestDialog: false,
      absolutelySure: false,
      cancellationResponse: {
        pdfDownloadKey: null,
      },
      cancellationSubmittedDate: Vue.moment().format('DD.MM.YYYY'),

      cancellationSummaryPdf: {
        url: null,
        link: null,
        blob: null,
      },

      siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      recaptchaResponse: '',
    }
  },

  methods: {

    onVerify: function (response) {
      this.recaptchaResponse = response;
      localStorage.setItem('x-recaptcha', response);
    },

    onExpired: function () {
      this.resetCaptcha();
    },

    resetCaptcha() {
      this.recaptchaResponse = '';
      localStorage.removeItem('x-recaptcha');
    },

    cleanPDF() {
      if (this.cancellationSummaryPdf.url) {
        this.cancellationSummaryPdf.url = window.URL.revokeObjectURL(this.cancellationSummaryPdf.url);
        this.cancellationSummaryPdf.blob = null;
      }
      this.cancellationSummaryPdf.url = null;
    },

    onDownloadCancellationSummaryPdf() {
      this.cleanPDF();
      this.downloadCancellationSummaryPdf(this.cancellationResponse.pdfDownloadKey, this.cancellationSummaryPdf);
    },

    downloadCancellationSummaryPdf(key, pdf) {
      this.sending = true;
      BinaryService.getCancellationPdf(key)
        .then(response => {
          this.sending = false;
          setTimeout(() => {
            pdf.blob = new Blob([response.data, 'utf-8'], {type: "application/pdf"});
            pdf.url = window.URL.createObjectURL(pdf.blob);

            if (pdf.link) {
              document.body.removeChild(pdf.link);
            }
            pdf.link = document.createElement('a');
            const id = 'Kündigungszusammenfassung (' + this.cancellation.customerNumber + ') Vertragsnummer ' + this.cancellation.contractNumber;
            pdf.link.href = pdf.url;
            pdf.link.setAttribute('download', id + '.pdf');
            pdf.link.setAttribute('id', id);
            document.body.appendChild(pdf.link);
            pdf.link.click();
          }, 50);
        })
        .catch(e => {
          this.sending = false;
          HttpErrorHandler.handleError(e, this, 'Fehler beim Laden des PDF für die Leistungsvereinbarung');
        })
    },


    onSelectAndCancel() {
      const route = '/Verwaltung/Traeger/Vertrag';
      if (!this.isAuthenticated) {
        this.$store.commit('challengeLoginRoute', route);
        this.$router.push('/Login');
      } else {
        this.$router.push(route);
      }
    },

    onCancel() {
      this.$refs.cancellationForm.validateCancellation();

      if (!this.$refs.cancellationForm.invalid) {
        this.confirmCancellationRequestDialog = true;
      }
    },

    onCancelContract() {
      this.cancelRegulatoryContract(this.cancellation)
    },

    cancelRegulatoryContract(cancellation) {
      this.sending = true;
      this.success = false;

      OrganizationService.cancelRegulatoryContract(cancellation)
        .then(response => {
          this.success = true;
          this.sending = false;
          this.cancellationResponse = response.data;
          this.$store.commit('successMsg', 'Die Kündigung des Vertrags <b>' + this.cancellation.contractNumber + '</b> wurde erfolgreich eingereicht. Sie erhalten in Kürze eine E-Mail von uns.');
          this.confirmCancellationRequestDialog = false;
          this.cancellationSubmittedDate = Vue.moment().format('DD.MM.YYYY');
        })
        .catch(e => {
          HttpErrorHandler.handleError(e, this, 'Fehler beim Einreichen der Kündigung für Vertragsnummer <b>' + this.cancellation.contractNumber + '</b>.');
          this.sending = false;
        })
    },
  },

  computed: {
    isAuthenticated() {
      return this.$store.getters.authenticated;
    },

    webAppConfig() {
      return this.$store.getters.webAppConfig;
    },
  }
}

</script>

<style scoped>
.md-title-textarea {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 300;
}
</style>